// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-case-studies-js": () => import("./../src/pages/case-studies.js" /* webpackChunkName: "component---src-pages-case-studies-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-services-js": () => import("./../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-talk-js": () => import("./../src/pages/talk.js" /* webpackChunkName: "component---src-pages-talk-js" */),
  "component---src-pages-team-js": () => import("./../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */),
  "component---src-pages-xprofile-js": () => import("./../src/pages/xprofile.js" /* webpackChunkName: "component---src-pages-xprofile-js" */),
  "component---src-templates-blog-js": () => import("./../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-profile-js": () => import("./../src/templates/profile.js" /* webpackChunkName: "component---src-templates-profile-js" */),
  "component---src-templates-project-js": () => import("./../src/templates/project.js" /* webpackChunkName: "component---src-templates-project-js" */)
}

